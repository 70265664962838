import React from 'react'

import { Box, Heading, Flex, Text } from '@chakra-ui/react'

import TeamMember from '~components/about/team-member'
import { FadeUpBox } from '~components/shared/custom-animation'

import colin from '~images/team/colin_BW.jpg'
import matthew from '~images/team/matthew_BW.jpg'
import george from '~images/team/George_BW.jpg'

const Team = () => {
    const members = [
        {
            name: 'Colin Hebert',
            pos: 'Co-Founder, Head of Operations',
            des:
                'Colin is the big dog. He handles all our business operations and accounts to keep the whole team running like a well-oiled machine. ' +
                'He has years of experience working in full-stack development and has strong leadership capabilities.',
            image: colin,
            linkedIn: 'https://www.linkedin.com/in/colin-hebert-bbb419143/',
            twitter: 'https://twitter.com/colin_abear',
        },
        {
            name: 'Matthew Ieyoub',
            pos: 'Co-Founder, Head of Technology',
            des:
                'Matthew is the wizard behind the curtain. He handles our system architecture and database management, as well as researching the technologies and frameworks we use. ' +
                'He has worked with both native Android and cross-platform development and deployment.',
            image: matthew,
            linkedIn: 'https://www.linkedin.com/in/matthew-ieyoub-66a431179/',
            twitter: 'https://twitter.com/matthewieyoub',
        },
        {
            name: 'George Villaume',
            pos: 'Co-Founder, Head of Design',
            des:
                'George leads Breach in making every experience we build beautiful and fun.  ' +
                'He has experience in graphic design and cross-platform development, and brings a strong UI/UX background to the team.',
            image: george,
            linkedIn: 'https://www.linkedin.com/in/george-villaume/',
            twitter: 'https://twitter.com/georgevillaume',
        },
    ]

    return (
        <Box pt={{ base: 6, md: 36 }} id="team">
            <Flex direction="column" justify="center" align="center">
                <FadeUpBox>
                    <Heading
                        pt={{ base: 8, md: 0 }}
                        pb={6}
                        fontSize={{ base: 'lg', md: 'xl' }}
                    >
                        Meet the Team
                    </Heading>
                </FadeUpBox>
                <FadeUpBox>
                    <Text
                        pb={24}
                        fontSize={['sm', 'md']}
                        align="center"
                        color="gray.600"
                    >
                        Our team is founded on collaboration. Each of our
                        members brings unique skills and perspectives to our
                        projects which allows us to work effeciently and with
                        the highest quality output.
                    </Text>
                </FadeUpBox>
            </Flex>
            {members.map((member, i) => (
                <TeamMember
                    name={member.name}
                    position={member.pos}
                    description={member.des}
                    img={member.image}
                    linkedIn={member.linkedIn}
                    twitterLink={member.twitter}
                />
            ))}
        </Box>
    )
}

export default Team
