import React from 'react'
import {
    Center,
    Box,
    Heading,
    Flex,
    Text,
    Image,
    useTheme,
} from '@chakra-ui/react'

import CreateMotionComponent, {
    FadeIn,
    FadeUpBox,
    Slide,
} from '~components/shared/custom-animation'

import team from '~images/team/the_team.jpg'
import about from '~images/home/about.svg'

const Story = () => {
    const theme = useTheme()
    const MotionBox = CreateMotionComponent(Box)

    return (
        <Box h="100vh">
            <Center w="100%" h="100%">
                <Flex
                    w="100%"
                    h={['40%', '100%']}
                    direction={['column', 'row']}
                    align="center"
                    justify="space-between"
                    px="10%"
                >
                    <Box w={{ base: '100%', md: '45%' }}>
                        <FadeUpBox>
                            <Heading
                                textAlign={{ base: 'center', md: 'left' }}
                                fontSize={['lg', '2xl']}
                                lineHeight={['40px', '60px']}
                                mb={2}
                            >
                                A company with{' '}
                                <Box
                                    as="span"
                                    fontWeight={700}
                                    fontStyle="italic"
                                >
                                    you
                                </Box>{' '}
                                in mind
                            </Heading>
                        </FadeUpBox>
                        <FadeUpBox>
                            <Text
                                textAlign={{ base: 'center', md: 'left' }}
                                fontSize={['sm', 'md']}
                                color={{ base: 'black', md: 'gray.500' }}
                            >
                                Our company is based on taking care of your
                                needs, first and foremost.
                            </Text>
                        </FadeUpBox>
                    </Box>
                    <Box w={['100%', '50%']} mb={[12, 0]}>
                        <FadeIn>
                            <Image
                                src={about}
                                w="100%"
                                h="100%"
                                objectFit="cover"
                            />
                        </FadeIn>
                    </Box>
                </Flex>
            </Center>
        </Box>
    )
}

export default Story
