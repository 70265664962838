import React from 'react'
import { motion, isValidMotionProp } from 'framer-motion'
import {
    forwardRef,
    Box,
    Flex,
    Heading,
    Text,
    Image,
    Link,
} from '@chakra-ui/react'

import { FadeUpBox, Slide } from '~components/shared/custom-animation'
import linkedin from '~images/socials/linkedinicon_dark.svg'
import twitter from '~images/socials/twittericon_dark.svg'

const MotionExternalLink = motion(Link)

const TeamMember = ({
    name,
    position,
    description,
    img,
    linkedIn,
    twitterLink,
}) => {
    return (
        <Flex
            mb={{ base: 36, md: 24 }}
            direction={{ base: 'column', md: 'row' }}
            justify="space-evenly"
            align="center"
        >
            <Slide dur={0.7}>
                <Image w="312px" h="312px" src={img} objectFit="cover" />
            </Slide>
            <Box w={{ base: '100%', md: '50%' }}>
                <FadeUpBox>
                    <Flex
                        pt={{ base: 16, md: 0 }}
                        w="100%"
                        direction="column"
                        justify={{ base: 'flex-start', md: 'space-evenly' }}
                        align={{ base: 'center', md: 'flex-start' }}
                    >
                        <Heading fontSize="lg" pb={2}>
                            {name}
                        </Heading>
                        <Text fontSize="sm" color="gray.300">
                            {position}
                        </Text>
                        <Box my={8} borderLeft="2px solid #f74734" pl={8}>
                            <Text color="gray.800">{description}</Text>
                        </Box>
                        <Flex
                            ml={'-7px'}
                            w="15%"
                            dir="row"
                            justify="space-between"
                            align="flex-start"
                        >
                            <MotionExternalLink
                                href={linkedIn}
                                whileHover={{ opacity: 0.5 }}
                                whileTap={{ scale: 0.95, y: 1 }}
                            >
                                <Image boxSize="32px" src={linkedin}></Image>
                            </MotionExternalLink>
                            <MotionExternalLink
                                href={twitterLink}
                                whileHover={{ opacity: 0.5 }}
                                whileTap={{ scale: 0.95, y: 1 }}
                            >
                                <Image boxSize="32px" src={twitter}></Image>
                            </MotionExternalLink>
                        </Flex>
                    </Flex>
                </FadeUpBox>
            </Box>
        </Flex>
    )
}

export default TeamMember
