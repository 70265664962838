import React from 'react'

import { Link } from 'gatsby'

import { Box, Heading, Flex, Text, Button } from '@chakra-ui/react'
import Banner from '~components/shared/banner'

const ProjectsBanner = () => (
    <Box>
        <Banner h="412px" color="gradientBg" shadow>
            <Flex h="100%" justify="center" direction="column">
                <Heading
                    fontSize={{ base: 'lg', md: 'xl' }}
                    mb={8}
                    color="white"
                >
                    Let us show you how we put our values to work
                </Heading>
                <Link to="/projects">
                    <Button bg="white" boxShadow="md">
                        <Text fontSize="sm" color="black">
                            View Projects
                        </Text>
                    </Button>
                </Link>
            </Flex>
        </Banner>
    </Box>
)

export default ProjectsBanner
