import React from 'react'

import { Box, Flex, Heading, Text } from '@chakra-ui/react'

import CoreValue from '~components/about/core-value'
import { FadeUpBox } from '~components/shared/custom-animation'

import teamwork from '~images/icons/teamwork.svg'
import target from '~images/icons/target.svg'
import startup from '~images/icons/startup.svg'
import clock from '~images/icons/clock.svg'
import handshake from '~images/icons/handshake.svg'

const Values = () => {
    return (
        <Box pb={24} pt={[10, 0]} id="values">
            <Flex align="center" direction="column">
                <FadeUpBox>
                    <Heading fontSize={['lg', 'xl']} pb={6}>
                        Core Values
                    </Heading>
                </FadeUpBox>
                <FadeUpBox>
                    <Text
                        fontSize={['sm', 'md']}
                        align="center"
                        pb={[6, 16]}
                        color="gray.600"
                    >
                        We strive to provide the highest quality products with
                        the best service, quickly and for the best price. Our
                        core values drive this process and set us apart from our
                        competitors.
                    </Text>
                </FadeUpBox>
                <Box>
                    <CoreValue
                        label="Collaboration"
                        info="Our team will work with you along the process to make sure every aspect is exactly how you envision it"
                        img={teamwork}
                        size={164}
                    />
                    <CoreValue
                        label="Intent"
                        info="Every action and every project is made with purpose and the end goal in mind"
                        img={target}
                        size={124}
                        reverse
                    />
                    <CoreValue
                        label="Ambition"
                        info="We look forward to challenging projects and continually becoming better"
                        img={startup}
                        size={196}
                    />
                    <CoreValue
                        label="Urgency"
                        info="We understand the importance of delivering results quickly without sacrificing quality"
                        img={clock}
                        size={96}
                        reverse
                    />
                    <CoreValue
                        label="Responsibility"
                        info="We take on a feeling of ownership with each project, so we treat it with the utmost care and import"
                        img={handshake}
                        size={156}
                    />
                </Box>
            </Flex>
        </Box>
    )
}

export default Values
