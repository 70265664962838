import React from 'react'
import { Box, Image } from '@chakra-ui/react'

import SEO from '~components/shared/SEO'
import ResponsiveBlock from '~components/shared/responsive-block'
import Story from '~components/about/story'
import Values from '~components/about/values'
import Team from '~components/about/team'
import Contact from '~components/about/contact'
import ProjectsBanner from '~components/about/projects-banner'

import drawing from '~images/backgrounds/roundbg.svg'

const AboutPage = () => {
    return (
        <>
            <SEO
                title="About"
                description="Meet the Breach Software team, see our core values, and get familiar with our process to see if we can help you."
            />
            <Box>
                <Image
                    position="absolute"
                    w="100vw"
                    h="100%"
                    bottom="20%"
                    left="0"
                    objectFit="cover"
                    src={drawing}
                    transform="scaleY(0.70)"
                    zIndex={-1}
                />
                <Box w="100%">
                    <Story />
                    <ResponsiveBlock>
                        <Values />
                        <ProjectsBanner />
                        <Team />
                    </ResponsiveBlock>
                    <Contact />
                </Box>
            </Box>
        </>
    )
}

export default AboutPage
