import React from 'react'
import { Flex, Box, Image, Heading, Text, useTheme } from '@chakra-ui/react'

import { FadeUpBox } from '~components/shared/custom-animation'

const CoreValue = ({ label, info, img, size, pl, reverse }) => {
    const theme = useTheme()
    return (
        <FadeUpBox>
            <Flex
                align="center"
                my={12}
                pr={{ base: '', md: reverse && pl }}
                pl={{ base: '', md: !reverse && pl }}
                direction={{
                    base: 'column',
                    md: reverse ? 'row-reverse' : 'row',
                }}
            >
                <Flex
                    justify="center"
                    align="center"
                    h={{ base: '84px', md: `${size}px` }}
                    w={{ base: '84px', md: `${size}px` }}
                    borderRadius="50%"
                    bg={`linear-gradient(45deg, ${theme.colors.brand['400']}, ${theme.colors.gradient})`}
                    mr={{ md: !reverse && 16 }}
                    ml={{ md: reverse && 16 }}
                    boxShadow="lg"
                    my={{ base: 4, md: 0 }}
                >
                    <Image h="60%" w="60%" src={img} />
                </Flex>
                <Flex
                    direction="column"
                    w={{ md: '60%' }}
                    align={{
                        base: 'center',
                        md: reverse ? 'flex-end' : 'flex-start',
                    }}
                >
                    <Heading fontSize={['lg', 'xl']} color="brand.400">
                        {label}
                    </Heading>
                    <Box w="80px" h="2px" bg="brand.400" mb={2} mt={2} />
                    <Text
                        align={{
                            base: 'center',
                            md: reverse ? 'right' : 'left',
                        }}
                        color="gray.600"
                    >
                        {info}
                    </Text>
                </Flex>
            </Flex>
        </FadeUpBox>
    )
}

export default CoreValue
