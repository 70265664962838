import React from 'react'
import { Box } from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'

const narrowMargins = [
    '-1.5em',
    '-2.5em',
    '-5em',
    '-6em',
    'calc(-50vw + 540px)',
]

const wideMargins = ['-1em', '-2em', '-3em', '-4em', 'calc(-50vw + 720px)']

const Banner = ({ h, color, wide, children, shadow }) => {
    return (
        <Box
            h={h}
            bg={color}
            mx={wide ? wideMargins : narrowMargins}
            boxShadow={
                shadow &&
                'inset 0px 11px 18px -10px rgba(0, 0, 0, 0.3), inset 0px -11px 18px -10px rgba(0, 0, 0, 0.3)'
            }
        >
            <ResponsiveBlock wide={wide}>{children}</ResponsiveBlock>
        </Box>
    )
}

export default Banner
